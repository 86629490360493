import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next'
import {
  Container,
  Header,
  Grid,
  Segment,
  List,
  Button
} from 'semantic-ui-react'

import Layout from '../components/Layouts/PageLayout'
import MapImage from '../components/Map/MapImage'
import ImageHeaderSlider from '../components/Items/ImageHeaderSlider'
import Bubble from '../components/Common/Bubble'
import Share from '../components/Common/Share'
import Breadcrumb from '../components/Common/Breadcrumb'
import Videos from '../components/Common/Videos'
import ImageSectionSlider from '../components/Items/ImageSectionSlider'
import PageHeader from '../components/Items/PageHeader'
import AdditionalOffers from '../components/Items/AdditionalOffers'
import PriceCard from '../components/Items/PriceCard'

import TourPackage from '../components/Items/TourPackage'
import { getRawImage } from '../utils/images'
import { unescape } from '../utils/helpers'

const DAYS_LIMIT = 3

class TourPage extends Component {
  state = { allDays: false }

  handleAllDays = () => this.setState({ allDays: true })

  render () {
    const { allDays } = this.state
    const { data, pageContext, t } = this.props

    const item = data.firestoreTour
    const {
      title,
      from,
      to,
      intro,
      categories,
      nights,
      days,
      collection,
      country,
      countrySlug,
      region,
      regionSlug,
      location,
      locationSlug,
      countryRegion,
      images,
      price,
      program,
      versions,
      map,
      teaser,
      seo,
      relations,
      videos,
      remarks
    } = item

    const { countryRegionSlug } = pageContext

    const subtitle = `${days} ${t('tour-details.days')} / ${nights} ${t(
      'tour-details.nights'
    )} ${t('trip-package.from')} ${from} ${t('dossier.program.to')} ${to}`
    const limitedProgram = allDays ? program : program.slice(0, DAYS_LIMIT)
    const limitedVersions = versions.slice(0, 3)

    const lowestPrice = versions.reduce((prev, curr) => {
      if (!prev || curr.prices < prev) {
        return curr.prices
      } else {
        return prev
      }
    }, 0)

    return (
      <>
        <Helmet>
          <title>{seo.htmlTitle}</title>

          <meta name='description' content={seo.description} />
          <meta
            name='image'
            content={getRawImage({ cloudinary: images.headers[0].cloudinary })}
          />

          <meta property='og:title' content={title} />
          <meta property='og:description' content={seo.description} />
          <meta
            property='og:image'
            content={getRawImage({ cloudinary: images.headers[0].cloudinary })}
          />

          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:creator' content='Tischler Reisen' />
          <meta name='twitter:title' content={title} />
          <meta name='twitter:description' content={seo.description} />
          <meta
            name='twitter:image'
            content={getRawImage({ cloudinary: images.headers[0].cloudinary })}
          />
        </Helmet>

        <Layout pageContext={pageContext}>
          <ImageHeaderSlider images={images.headers} />

          {(teaser.header || teaser.content) && (
            <Bubble title={teaser.header} text={teaser.content} inverted />
          )}

          <PageHeader
            categories={categories}
            title={seo.h1}
            intro={intro}
            subtitle={subtitle}
          />

          <Segment vertical padded='very' className='gradient' inverted>
            <Container>
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column computer={12}>
                    <Breadcrumb
                      service={collection}
                      country={country}
                      countrySlug={countrySlug}
                      countryRegion={countryRegion}
                      countryRegionSlug={countryRegionSlug}
                      region={region}
                      regionSlug={regionSlug}
                      location={location}
                      locationSlug={locationSlug}
                      title={title}
                    />
                  </Grid.Column>
                  <Grid.Column textAlign='right' computer={4}>
                    <Share color='grey' />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Column computer={11} tablet={11}>
                  <Segment vertical padded='very'>
                    <Header as='h2' color='red'>
                      {t('tour-details.header.programm')}
                    </Header>
                    <List horizontal>
                      <List.Item as='span'>
                        {t('tour-details.header.breakfast')}
                      </List.Item>
                      <List.Item as='span'>
                        {t('tour-details.header.lunch')}
                      </List.Item>
                      <List.Item as='span'>
                        {t('tour-details.header.dinner')}
                      </List.Item>
                    </List>
                    {limitedProgram.map(({ day, meals, title, text }) => {
                      let mealsInfo = []
                      if (meals.B === '1') {
                        mealsInfo.push(t('tour-details.header.b'))
                      }
                      if (meals.L === '1') {
                        mealsInfo.push(t('tour-details.header.l'))
                      }
                      if (meals.D === '1') {
                        mealsInfo.push(t('tour-details.header.d'))
                      }

                      return (
                        <Segment vertical padded key={day}>
                          <Header as='h3'>
                            <span className='tour-red'>
                              {t('tour-details.day')} {day}:
                            </span>{' '}
                            {unescape(title)}{' '}
                            {mealsInfo.length > 0 &&
                              `(${mealsInfo.join(', ')})`}
                          </Header>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: text.replace(/(\n)+/g, '<br />')
                            }}
                          />
                        </Segment>
                      )
                    })}
                    {program.length > DAYS_LIMIT && !allDays && (
                      <Segment padded='very' basic textAlign='center'>
                        <Button
                          size='large'
                          color='red'
                          icon
                          labelPosition='right'
                          inverted
                          onClick={this.handleAllDays}
                        >
                          {t('tour-details.more')}
                          <i className='icon ta-arrow-1-right' />
                        </Button>
                      </Segment>
                    )}
                  </Segment>
                </Grid.Column>

                <Grid.Column computer={5} tablet={5}>
                  <PriceCard
                    header={t('tour-details.tour')}
                    content={t('tour-details.price')}
                    price={lowestPrice || price || '0'}
                    type={collection}
                    item={item}
                    centered
                    showPrice
                  />

                  {map && (
                    <MapImage cloudinary={map} alt={t('tour-details.map')} />
                  )}

                  {videos && videos.length > 0 && <Videos videos={videos} />}

                  {remarks && remarks.length > 0 && (
                    <div className='remarks'>
                      {remarks.map((remark, idx) => (
                        <div key={idx} className='remark-item'>
                          <Header as='h3' color='red'>
                            {remark.name}
                          </Header>
                          <div
                            dangerouslySetInnerHTML={{ __html: remark.text }}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </Grid.Column>
              </Grid>

              {images && (
                <ImageSectionSlider images={images.standard} withLightbox />
              )}
            </Container>
          </Segment>

          {/* VERSIONS */}
          <Segment
            vertical
            padded='very'
            basic
            id='packages-list'
            className='lightgrey'
            inverted
          >
            <Container>
              <Header as='h2' color='red'>
                {t('tour-details.packages')}
              </Header>
              <Segment padded vertical basic>
                <Grid
                  stackable
                  doubling
                  columns={limitedVersions.length === 1 ? 'two' : 'equal'}
                  centered={limitedVersions.length === 1}
                >
                  {limitedVersions.map((version, i) => (
                    <Grid.Column key={version.name} stretched>
                      <TourPackage version={version} item={item} index={i} />
                    </Grid.Column>
                  ))}
                </Grid>
              </Segment>
            </Container>
          </Segment>

          <Container>
            <AdditionalOffers country={country} relations={relations} />
          </Container>
        </Layout>
      </>
    )
  }
}

export default withTranslation()(TourPage)

/**************************************************************
 * GRAPHQL
 **************************************************************/
export const pageQuery = graphql`
  query TourQuery($slug: String!, $lang: String!) {
    firestoreTour(slug: { eq: $slug }, lang: { eq: $lang }) {
      title
      lang
      country
      intro
      collection
      categories
      location
      region
      nights
      days
      from
      to
      map
      seo {
        htmlTitle
        description
        h1
      }
      teaser {
        header
        content
      }
      images {
        headers {
          id
          cloudinary
          title
          alt
          transformation
        }
        standard {
          id
          cloudinary
          title
          alt
        }
      }
      coverImage
      program {
        day
        meals {
          B
          L
          D
        }
        text
        title
      }
      versions {
        versCode
        package
        name
        prices
        hotels {
          hotelID
          hotelName
          hotelStars
          to
          roomName
          from
          lead
          images {
            cloudinary
          }
        }
        schedule {
          dates {
            date
            text
          }
        }
      }
      remarks {
        name
        text
      }
      slug
      countrySlug
      regionSlug
      locationSlug
    }
  }
`
